<template>
  <span class="base-badge" :class="`base-badge--${view}`">
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    view: {
      type: String,
      default: "orange",
    },
  },
};
</script>

<style lang="scss" scoped>
.base-badge {
  border-radius: 10px;
  font-size: $font-size-sm;
  padding: 2px 8px;

  &--orange {
    background-color: $orange;
  }
}
</style>
