<template>
  <div class="site-header-mobile-nav-subnav">
    <div
      class="site-header-mobile-nav-subnav__title"
      :class="{ 'site-header-mobile-nav-subnav__title--active': isShow }"
      @click="isShow = !isShow"
    >
      <slot></slot>

      <icon name="down-menu" width="6" height="5" />
    </div>

    <ul v-show="isShow" class="site-header-mobile-nav-subnav__list">
      <li
        v-for="(item, index) in list"
        :key="`${name}-${index}`"
        class="site-header-mobile-nav-subnav__item"
      >
        <router-link
          :to="{ name: item.route.name }"
          class="site-header-mobile-nav-subnav__link"
        >
          {{ item.title }}
        </router-link>
        <base-badge
          v-if="item.badge"
          :view="item.badge.view"
          class="site-header-mobile-nav-subnav__badge"
        >
          <template #default>
            {{ item.badge.title }}
          </template>
        </base-badge>
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from "@/components/shared/Icon";
import BaseBadge from "@/components/shared/BaseBadge";

export default {
  components: {
    Icon,
    BaseBadge,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  watch: {
    "$mq.resize"() {
      this.isShow = false;
    },
    $route() {
      this.isShow = false;
    },
  },
  mounted() {
    const self = this;

    window.addEventListener("click", function (e) {
      if (self.isShow && !self.$el.contains(e.target)) {
        self.isShow = false;
      }
    });
  },
  destroyed() {
    document.removeEventListener("click", null, false);
  },
};
</script>

<style lang="scss" scoped>
.site-header-mobile-nav-subnav {
  &__title {
    font-size: $font-size-md;
    height: 100%;
    padding: 17px 6px;
    text-align: center;

    @media (min-width: 375px) {
      font-size: inherit;
      padding: 15px 6px;
    }

    @include media-breakpoint-up(sm) {
      padding-left: 0;
      padding-right: 0;
    }

    @media (min-width: 375px) {
      font-size: inherit;
      padding: 15px 6px;
    }

    &--active {
      background: $violet-blue;
    }
  }

  &__list {
    @include list-unstyled;
    background-color: $violet-blue;
    left: 0;
    padding: 35px 80px;
    position: fixed;
    right: 0;
    top: 54px;

    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
      padding-left: 55px;
      padding-right: 55px;
    }

    @include media-breakpoint-up(md) {
      padding-left: 85px;
      padding-right: 85px;
    }
  }

  &__item {
    & + & {
      margin-top: 30px;

      @include media-breakpoint-up(sm) {
        margin-top: 0;
      }
    }
  }

  &__link {
    color: rgba($white, 0.7);

    &.router-link-active {
      color: $white;
    }
  }

  &__badge {
    margin-left: 4px;
  }
}
</style>
