import { render, staticRenderFns } from "./TheSpacecrabs.vue?vue&type=template&id=d223e664&scoped=true&functional=true&"
var script = {}
import style0 from "./TheSpacecrabs.vue?vue&type=style&index=0&id=d223e664&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "d223e664",
  null
  
)

export default component.exports