export default [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "page" */ "../views/Home"),
  },
  {
    path: "/about",
    name: "StaticAbout",
    component: () =>
      import(/* webpackChunkName: "page" */ "../views/StaticAbout"),
  },
  {
    path: "/partnership",
    name: "StaticPartnership",
    component: () =>
      import(/* webpackChunkName: "page" */ "../views/StaticPartnership"),
  },
  {
    path: "/memo",
    name: "StaticMemo",
    component: () =>
      import(/* webpackChunkName: "page" */ "../views/StaticMemo"),
  },
  {
    path: "/legal",
    name: "StaticLegal",
    component: () =>
      import(/* webpackChunkName: "page" */ "../views/StaticLegal"),
  },
  {
    path: "/payment-url",
    name: "PaymentUrl",
    component: () =>
      import(/* webpackChunkName: "page" */ "../views/PaymentUrl"),
  },
  {
    path: "/404",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "page" */ "../views/NotFound"),
  },
];
