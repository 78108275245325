<template>
  <div
    class="indicator"
    :class="[
      { 'indicator--before': type === 'before' },
      { 'indicator--after': type === 'after' },
      getWorkStatus ? 'indicator--online' : 'indicator--offline',
    ]"
  >
    <template v-if="hasSlotDefault">
      <slot></slot>
    </template>
    <template v-else>
      <template v-if="getWorkStatus">Рабочее время</template>
      <template v-else>Нерабочее время</template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    type: {
      type: String,
      default: "before",
    },
  },
  computed: {
    ...mapGetters("time", {
      getWorkStatus: "getWorkStatus",
    }),
    hasSlotDefault() {
      return this.$slots.default;
    },
  },
};
</script>

<style lang="scss" scoped>
.indicator {
  display: inline-block;
  position: relative;

  &::before {
    border-radius: 50%;
    content: "";
    display: block;
    height: 6px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
  }

  &--before {
    padding-left: 10px;

    &::before {
      left: 0;
    }
  }

  &--after {
    padding-right: 1em;

    &::before {
      right: 0;
    }
  }

  &--online {
    &::before {
      background-color: $acid-green;
    }
  }

  &--offline {
    &::before {
      background-color: $red;
    }
  }
}
</style>
