import isObject from "lodash/isObject";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import format from "date-fns/format";
import parse from "date-fns/parse";
import ru from "date-fns/locale/ru";
import addDays from "date-fns/addDays";
import subYears from "date-fns/subYears";
import addYears from "date-fns/addYears";
import isValid from "date-fns/isValid";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import { isEqual as isDateEqual } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

const today = new Date();
today.setHours(0, 0, 0, 0);

/**
 * Формитирование даты в камчасткое время
 */
export function toKamchatka(date) {
  const zonedDate = utcToZonedTime(date, "Asia/Kamchatka");
  const hour = zonedDate.getHours();

  return { date: zonedDate, hour: hour };
}

/**
 * Проверка поля на существование
 * @param {Array}
 * @returns {boolean}
 */
export function isFieldEmpty(value) {
  if (!value) return true;

  if (isObject(value)) return isEmpty(value);

  return value === "";
}

/**
 * Проверяет, пустая ли форма
 * @param {Object} форма
 * @returns {Boolean}
 */
export function isFormEmpty(form) {
  let result = true;

  Object.keys(form).forEach((key) => {
    if (!isFieldEmpty(form[key])) {
      result = false;
    }
  });

  return result;
}

/**
 * Очистка формы
 * @param {Object} форма
 * @param {Array} исключение
 * @returns {Object} форма
 */
export function cleanForm(oldForm, exclude = null) {
  const newForm = {};

  Object.keys(oldForm).forEach((key) => {
    if (key !== exclude) newForm[key] = null;
  });

  return newForm;
}

/**
 * Присваивает выбранное значение
 * @param {Object} объект
 * @param {String} свойство
 * @param {Array} список
 * @returns {Object} значение
 */
export function setField(value, field, list) {
  if (!list || list.length === 0) return null;

  return list.find((obj) => obj[field] === value);
}

/**
 * Форматирует цену с разделениями на десятки
 * @param {Number} значение
 * @returns {String} значение
 */
export function formatNumber(value) {
  if (!value) return "";
  if (value === 0) return 0;

  let mutableValue = Math.round(parseFloat(value) * 100) / 100;
  mutableValue = String(mutableValue).replace(
    /(\d)(?=(\d{3})+([^\d]|$))/g,
    "$1 "
  );
  mutableValue = String(mutableValue).replace(/\./g, ",");

  return mutableValue;
}

/**
 * Склонение
 * @param {Number} значение
 * @param {Array} массив заголовков
 * @returns {String} значение
 */
export function declOfNum(value, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  return (
    value +
    " " +
    titles[
      value % 100 > 4 && value % 100 < 20
        ? 2
        : cases[value % 10 < 5 ? value % 10 : 5]
    ]
  );
}

/**
 * Преобразует строку в булевое значение
 * @param значение
 * @returns {Boolean}
 */
export function getBoolean(value) {
  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    default:
      return false;
  }
}

/**
 * Форматирует дату
 * @param {String} дата
 * @param {String} формат даты
 * @returns {String}
 */
export function formatDate(date, dateFormat) {
  const value = new Date(date);

  return format(value, dateFormat, { locale: ru });
}

/**
 * Проверяет дату на валидность
 * @param {String} дата
 * @param {String} формат даты
 * @returns {Boolean}
 */
export function isDateValid(value, token) {
  const prev = subYears(today, 100);
  const next = addYears(today, 100);

  const date = parse(value, token, new Date());

  return isValid(date) && isAfter(date, prev) && isBefore(date, next);
}

/**
 * Удаление подстроки
 * @param {String} строка
 * @param {String} подстрока
 * @returns {String}
 */
export function removeSubstring(val, sub) {
  if (!val || val.length === 0) return "";
  return val.replace(sub, "");
}

/**
 * Получить текст поисковой строки
 * @param {Object} строка
 * @returns {String}
 */
export function getSearchText(query) {
  const text = [];

  if ("date" in query) text.push(formatDate(query.date, "d MMMM"));

  if ("checkIn" in query && "checkOut" in query) {
    text.push(
      `${removeSubstring(
        formatDate(query.checkIn, "d MMM"),
        "."
      )} — ${removeSubstring(formatDate(query.checkOut, "d MMM"), ".")}`
    );
  }

  if ("spread" in query)
    text.push(
      `± ${declOfNum(parseInt(query.spread), ["день", "дня", "дней"])}`
    );

  let count = 0;

  if ("adult" in query) count += parseInt(query.adult);
  if ("child" in query) count += parseInt(query.child);

  if (count > 0) text.push(declOfNum(count, ["турист", "туриста", "туристов"]));

  if ("priceMin" in query && "priceMax" in query)
    text.push(`${query.priceMin}—${query.priceMax} руб.`);
  else {
    if ("priceMin" in query) text.push(`от ${query.priceMin} руб.`);
    if ("priceMax" in query) text.push(`до ${query.priceMax} руб.`);
  }

  return text.length > 0 ? text.join(", ") : null;
}

/**
 * Общее количество туристов
 * @param {Object}
 * @returns {Number}
 */
export function getTouristCount(routeQuery) {
  let count = 0;

  if ("adult" in routeQuery) count += parseInt(routeQuery.adult);
  if ("child" in routeQuery) count += parseInt(routeQuery.child);

  return count;
}

/**
 * Получить цену либо из router, либо из claim
 * @param {Object}
 * @param {Object}
 * @returns {Number}
 */
export function getPrice(routeQuery, claim) {
  const priceQuery = isNaN(routeQuery.price)
    ? null
    : parseInt(routeQuery.price);
  const priceClaim = isNaN(claim.price) ? null : parseInt(claim.price);

  if (!priceClaim) return priceQuery;

  return priceQuery === priceClaim ? priceQuery : priceClaim;
}

/**
 * Сформировать параметры claim
 * @param {String}
 * @param {Object}
 * @returns {Object}
 */
export function getCalcClaimParams(offerId, list) {
  return {
    offerId: offerId,
    people: list.map((item) => {
      const tourist = {
        born: item.born,
        nationality: item.nationality ? item.nationality.code : null,
      };

      return tourist;
    }),
  };
}

/**
 * Проверяет, дефолтные значения у формы фильтра или нет
 * @param {Object}
 * @param {Object}
 * @param {Array}
 * @returns {Boolean}
 */
export function checkFormDefault(currentForm, defaultForm, typeList) {
  const result = [];

  for (const property in currentForm) {
    const value = currentForm[property];

    if (property === "type" && typeList.length > 0) {
      result.push(isEqual(value, typeList));
    } else {
      result.push(isEqual(value, defaultForm[property]));
    }
  }

  return !result.includes(false);
}

/**
 * Получает доступные даты
 * @param {Object}
 * @returns {Array}
 */
export function getValidDates(calendar) {
  const startDate = new Date(calendar.startDate).setHours(0, 0, 0, 0);
  const dates = calendar.dateStr ? calendar.dateStr.split("") : [];
  const validDates = dates
    .map((day, i) => (day === "1" ? addDays(startDate, i) : false))
    .filter((day) => day);

  return validDates;
}

/**
 * Формирует условия для получения доступных дат
 * @param {String}
 * @param {Object}
 * @param {Object}
 * @param {Array}
 * @param {Object}
 * @returns {Object}
 */
export function getCalendarParams(type, form, tourist, typeList, id) {
  const condition = {
    from: type,
  };

  if (form.type && form.type.length > 0 && form.type.length < typeList.length)
    condition.type = form.type.map((tour) => tour.id);
  if (tourist.adult > 0) condition.adult = tourist.adult;
  if (tourist.child > 0) condition.child = tourist.child;
  if (id) condition.id = id;

  return condition;
}

/**
 * Проверяет, доступна ли дата
 */
export function isDateAllowed(dates, date) {
  const dateNew = date.setHours(0, 0, 0, 0);
  return !!dates.find((d) => isDateEqual(d, dateNew));
}

/**
 * Меняет title у недоступных дат в календаре
 */
export function setDataTitle() {
  const cellList = Array.from(document.getElementsByClassName("cell disabled"));

  cellList.forEach((item) => {
    item.setAttribute("title", "Эта дата недоступна");
  });
}

/**
 * Туристы
 * @param {Object}
 * @param {Object}
 * @returns {Object}
 */

export function setTouristParams(query, form) {
  if ("adult" in query) form.adult = parseInt(query.adult);
  if ("child" in query) form.child = parseInt(query.child);
  if ("ages" in query)
    form.ages = query.ages.split(",").filter((item) => item && item.length > 0);

  if (form.ages.length > form.child) form.child = form.ages.length;
  if (form.child > form.ages.length) form.ages.length = form.child;

  return form;
}

export function setTouristQuery(form) {
  const query = {};

  if (form.adult !== 0) query.adult = form.adult;
  if (form.child !== 0) query.child = form.child;

  if (form.ages.length > 0) {
    const ages = form.ages.filter((item) => item && item.length > 0).join(",");
    if (ages.length > 0) query.ages = ages;
  }

  return query;
}

/**
 * Убирает пробелы вначале и в конце строки
 * @param {String} Строка
 * @returns {String} Очищенная строка
 */
export function onTrim(str) {
  if (typeof str === "string" || str instanceof String) {
    return str.trim();
  }

  return str;
}
