<template>
  <ul class="site-footer-contact">
    <li class="site-footer-contact__item site-footer-contact__item--phone">
      <a
        href="tel:+74152480750"
        class="site-footer-contact__link site-footer-contact__link--underline"
        >+7 (4152) 480-750</a
      >
    </li>
    <li class="site-footer-contact__item site-footer-contact__item--phone">
      <a
        href="tel:+79004440707"
        class="site-footer-contact__link site-footer-contact__link--underline"
        >+7 (900) 444-07-07</a
      >
    </li>
    <li class="site-footer-contact__item site-footer-contact__item--indicator">
      <indicator class="text-small" type="before" />
    </li>
    <li class="site-footer-contact__item site-footer-contact__item--email">
      <a
        class="site-footer-contact__link site-footer-contact__link--underline"
        href="mailto:ask@zimaletokamchatka.ru"
        >ask@zimaletokamchatka.ru</a
      >
    </li>
  </ul>
</template>

<script>
import Indicator from "../shared/Indicator";

export default {
  components: {
    Indicator,
  },
};
</script>

<style lang="scss" scoped>
.site-footer-contact {
  @include list-unstyled;
  @include make-row;

  align-items: center;
  margin-bottom: 0;

  &__item {
    @include make-col-ready;

    &:not(.site-footer-contact__item--indicator) {
      @include make-col-auto;
    }

    &--indicator {
      order: -1;

      @include media-breakpoint-up(xl) {
        @include make-col-auto;
        order: 0;
      }
    }

    &--email {
      @include media-breakpoint-up(sm) {
        text-align: right;
      }
    }
  }

  &__link {
    color: $white;
    display: block;
    padding: 6px 0;

    @include media-breakpoint-up(xl) {
      padding-bottom: 15px;
      padding-top: 15px;
    }

    &--underline {
      @include media-breakpoint-down(lg) {
        @include link-underline($white);
      }
    }

    .site-footer-contact__item--email & {
      @include media-breakpoint-up(xl) {
        padding-left: 50px;
      }
    }
  }
}
</style>
