import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";

import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";

import Vue from "vue";
import * as Sentry from "@sentry/vue";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    if (process.env.NODE_ENV === "production") {
      Sentry.withScope((scope) => {
        graphQLErrors.map(({ message, extensions }) => {
          const { code, path } = extensions;

          scope.setExtras({
            path: path,
            code: code,
          });

          Sentry.captureMessage(new Error(`GraphQLError ${message}`));

          Vue.notify({
            title: code,
            text: message,
            type: "error",
          });
        });
      });
    } else {
      graphQLErrors.map(({ message, extensions }) => {
        const { code, path } = extensions;

        console.error(`[GraphQL error]: ${code} ${path} ${message}`);

        Vue.notify({
          title: code,
          text: message,
          type: "error",
        });
      });
    }
  }

  if (networkError) {
    if (process.env.NODE_ENV === "production") {
      Sentry.captureMessage(`[Network error]: ${networkError}`);
    } else {
      console.error(`[Network error]: ${networkError}`);
      Vue.notify({
        text: networkError,
        type: "error",
      });
    }
  }
});

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GQL_LINK,
});

const link = ApolloLink.from([errorLink, httpLink]);

// Cache implementation
const cache = new InMemoryCache({
  dataIdFromObject: (object) => object.key || null,
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link,
  cache,
  connectToDevTools: true,
});

export default apolloClient;
