import Vue from "vue";
import Vuex from "vuex";

import time from "./modules/time";
import settings from "./modules/settings";
import booking from "@/modules/booking/store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    time,
    settings,
    booking,
  },
});
