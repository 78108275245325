import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import apollo from "./apollo";

import "./styles/main.scss";
import * as options from "@/config/options";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import VueApollo from "vue-apollo";

import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/ru";
import VTooltip from "v-tooltip";
import YmapPlugin from "vue-yandex-maps";
import VModal from "vue-js-modal";
import VueMask from "v-mask";
import Vuelidate from "vuelidate";
import vMediaQuery from "v-media-query";
import Vue2TouchEvents from "vue2-touch-events";
import Notifications from "vue-notification";

import Loading from "@/components/shared/Loading";

Vue.use(VueApollo);
Vue.use(VTooltip);
Vue.use(YmapPlugin, options.VUE_YANDEX_MAP);
Vue.use(VModal);
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(vMediaQuery, options.VUE_MEDIAQUERY);
Vue.use(Vue2TouchEvents);
Vue.use(Notifications);

Vue.component("Multiselect", Multiselect);
Vue.component("DatePicker", DatePicker);
Vue.component("Loading", Loading);

Vue.config.productionTip = false;

// Оставить sentry только для production
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue: Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    environment: process.env.NODE_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", process.env.VUE_APP_SITE_LINK, /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

const apolloProvider = new VueApollo({
  defaultClient: apollo,
});

new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
