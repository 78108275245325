// Options

export const VUE_YANDEX_MAP = {
  lang: "ru_RU",
  coordorder: "latlong",
  version: "2.1",
};

export const VUE_MEDIAQUERY = {
  variables: {
    xs: 0,
    sm: "575px",
    md: "767px",
    lg: "991px",
    xl: "1251px",
  },
};
