<template>
  <footer class="site-footer">
    <div class="site-footer__top">
      <div class="container">
        <div class="row align-items-end">
          <div class="col-lg-7">
            <site-footer-contact />
          </div>
          <div class="col-lg-5">
            <site-footer-nav class="mt-3 mt-lg-0" />
          </div>
        </div>
      </div>
    </div>
    <div class="site-footer__bottom">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col">
            <the-copyright />
          </div>
          <div class="col-lg-2">
            <the-spacecrabs />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import SiteFooterNav from "@/components/layout/SiteFooterNav";
import SiteFooterContact from "@/components/layout/SiteFooterContact";
import TheSpacecrabs from "@/components/layout/TheSpacecrabs";
import TheCopyright from "@/components/layout/TheCopyright";

export default {
  components: {
    SiteFooterNav,
    SiteFooterContact,
    TheSpacecrabs,
    TheCopyright,
  },
};
</script>

<style lang="scss" scoped>
.site-footer {
  @media print {
    display: none;
  }

  &__top {
    background: $purplish-blue;
    color: $white;
    padding-bottom: 10px;
    padding-top: 10px;

    a {
      color: $white;

      &:hover {
        color: inherit;
      }
    }
  }

  &__bottom {
    padding: 12px 0;
  }
}
</style>
