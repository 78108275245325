<template>
  <ul class="site-header-mobile-nav">
    <li class="site-header-mobile-nav__item">
      <site-header-mobile-nav-subnav name="tour-list" :list="tourList">
        <template #default> Туры </template>
      </site-header-mobile-nav-subnav>
    </li>

    <li class="site-header-mobile-nav__item">
      <router-link
        :to="{ name: 'ListHotel' }"
        class="site-header-mobile-nav__link"
      >
        Гостиницы
      </router-link>
    </li>
    <li class="site-header-mobile-nav__item">
      <router-link
        :to="{ name: 'StaticAbout' }"
        class="site-header-mobile-nav__link"
      >
        О компании
      </router-link>
    </li>
  </ul>
</template>

<script>
import SiteHeaderMobileNavSubnav from "./SiteHeaderMobileNavSubnav";

export default {
  components: {
    SiteHeaderMobileNavSubnav,
  },
  data() {
    return {
      tourList: [
        {
          title: "Однодневные",
          route: {
            name: "ListTour",
          },
          badge: null,
        },
        {
          title: "Многодневные",
          route: {
            name: "ListProgram",
          },
          badge: null,
        },
        {
          title: "Чартеры",
          route: {
            name: "ListCharter",
          },
          badge: {
            title: "Новинка",
            view: "orange",
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.site-header-mobile-nav {
  @include list-unstyled;
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 54px;
  margin-bottom: 0;
  position: relative;

  &__item {
    flex-grow: 1;
  }

  &__link {
    color: $white;
    display: block;
    font-size: $font-size-md;
    height: 100%;
    padding: 17px 6px;
    text-align: center;

    @media (min-width: 375px) {
      font-size: inherit;
      padding: 15px 6px;
    }

    @include media-breakpoint-up(sm) {
      padding-left: 0;
      padding-right: 0;
    }

    &.router-link-active {
      background: $violet-blue;
      color: inherit;
    }
  }
}
</style>
