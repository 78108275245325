<template>
  <ul class="site-header-desktop-nav">
    <li class="site-header-desktop-nav__item">
      <router-link to="/tour" class="nav__link site-header-desktop-nav__link">
        Однодневные
      </router-link>
    </li>
    <li class="site-header-desktop-nav__item">
      <router-link
        to="/program"
        class="nav__link site-header-desktop-nav__link"
      >
        Многодневные
      </router-link>
    </li>
    <li class="site-header-desktop-nav__item">
      <router-link
        to="/charter"
        class="nav__link site-header-desktop-nav__link"
      >
        Чартеры
        <base-badge class="site-header-desktop-nav__badge">
          <template #default> Новинка </template>
        </base-badge>
      </router-link>
    </li>
    <li class="site-header-desktop-nav__item">
      <router-link to="/hotel" class="nav__link site-header-desktop-nav__link">
        Гостиницы
      </router-link>
    </li>
    <li class="site-header-desktop-nav__item">
      <router-link
        :to="{ name: 'StaticAbout' }"
        class="nav__link site-header-desktop-nav__link"
      >
        О компании
      </router-link>
    </li>
  </ul>
</template>

<script>
import BaseBadge from "@/components/shared/BaseBadge";

export default {
  components: {
    BaseBadge,
  },
};
</script>

<style lang="scss" scoped>
.site-header-desktop-nav {
  @include list-unstyled;
  @include make-row;
  margin-bottom: 0;

  @include media-breakpoint-up(lg) {
    justify-content: flex-end;
    margin-left: 0;
    margin-right: 0x;
  }

  @include media-breakpoint-up(xl) {
    justify-content: flex-start;
  }

  &__item {
    @include make-col-ready;

    @include media-breakpoint-up(lg) {
      @include make-col-auto;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__link {
    color: $white;
    display: inline-block;
    padding: 15px 40px;

    @include media-breakpoint-up(lg) {
      padding: 20px 12px;
    }

    @include media-breakpoint-up(xl) {
      padding-left: 17px;
      padding-right: 17px;
    }

    &.router-link-active,
    &:hover {
      background: $violet-blue;
      color: inherit;
    }
  }

  &__badge {
    margin-left: 4px;

    @include media-breakpoint-only(lg) {
      display: none;
    }
  }
}
</style>
