import { toKamchatka } from "@/utils";
import isSaturday from "date-fns/isSaturday";
import isSunday from "date-fns/isSunday";

const workours = {
  weekday: {
    start: 11,
    finish: 19,
  },
  saturday: {
    start: 11,
    finish: 15,
  },
};

const state = {
  hour: null,
  saturday: false,
  sunday: false,
};

const getters = {
  getWorkStatus: (state) => {
    if (state.sunday) return false;
    if (state.saturday)
      return (
        state.hour >= workours.saturday.start &&
        state.hour < workours.saturday.finish
      );
    return (
      state.hour >= workours.weekday.start &&
      state.hour < workours.weekday.finish
    );
  },
};

const actions = {
  setHour: ({ commit }) => {
    commit("mutateHour");
  },
};

const mutations = {
  mutateHour(state) {
    const output = toKamchatka(new Date());

    state.saturday = isSaturday(output.date);
    state.sunday = isSunday(output.date);
    state.hour = output.hour;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
