<template>
  <a href="/" class="the-logo">
    <img
      :src="image"
      :srcset="image2x + ' 2x'"
      alt=""
      class="the-logo__image"
    />
  </a>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.$mq.resize && this.$mq.below(this.$mv.lg);
    },
    image() {
      return this.isMobile
        ? require("../../assets/logo-mobile.png")
        : require("../../assets/logo.png");
    },
    image2x() {
      return this.isMobile
        ? require("../../assets/logo-mobile@2x.png")
        : require("../../assets/logo@2x.png");
    },
  },
};
</script>

<style lang="scss" scoped>
.the-logo {
  &__image {
    height: auto;
    width: 70px;

    @include media-breakpoint-up(lg) {
      width: 200px;
    }

    @include media-breakpoint-up(xl) {
      width: 230px;
    }
  }
}
</style>
