const state = {
  sWidth: 0,
  modal: false,
  bWidth: 150,
};

const actions = {
  setSettings: ({ commit }, payload) => {
    commit("setSettings", payload);
  },
};

const mutations = {
  setSettings(state, payload) {
    state[payload.key] = payload.value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
