<template>
  <header class="site-header">
    <div class="container">
      <div class="site-header__body">
        <the-logo class="site-header__logo" />

        <site-header-mobile-nav
          v-if="isMobile"
          class="site-header__mobile-nav"
        />

        <div v-else class="row align-items-center">
          <div class="col-lg-8 col-xl-7 offset-lg-2 offset-xl-3">
            <site-header-desktop-nav />
          </div>
          <div class="col-lg-2">
            <indicator class="site-header__indicator" type="after">
              +7 (4152) <span class="text-bold">480-750</span>
            </indicator>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import TheLogo from "./TheLogo";
import SiteHeaderDesktopNav from "./SiteHeaderDesktopNav";
import SiteHeaderMobileNav from "./SiteHeaderMobileNav";
import Indicator from "../shared/Indicator";

export default {
  components: {
    TheLogo,
    SiteHeaderDesktopNav,
    SiteHeaderMobileNav,
    Indicator,
  },
  computed: {
    isMobile() {
      return this.$mq.resize && this.$mq.below(this.$mv.lg);
    },
  },
};
</script>

<style lang="scss" scoped>
.site-header {
  background: $purplish-blue;
  color: $white;
  margin-bottom: $header-margin-bottom;
  min-height: 50px;

  @media print {
    display: none;
  }

  @include media-breakpoint-down(md) {
    height: 54px;
    margin-bottom: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 20;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 10px;
  }

  @include media-breakpoint-up(xl) {
    margin-top: 39px;
  }

  &__body {
    position: relative;
  }

  &__logo {
    position: absolute;
    top: 0;
    z-index: 12;

    @include media-breakpoint-up(lg) {
      top: -10px;
    }

    @include media-breakpoint-up(xl) {
      top: -39px;
    }
  }

  &__mobile-nav {
    margin-left: 70px;
  }

  &__indicator {
    width: 100%;
    text-align: right;
  }
}
</style>
