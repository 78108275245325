import { render, staticRenderFns } from "./BaseBadge.vue?vue&type=template&id=7ebe6641&scoped=true&"
import script from "./BaseBadge.vue?vue&type=script&lang=js&"
export * from "./BaseBadge.vue?vue&type=script&lang=js&"
import style0 from "./BaseBadge.vue?vue&type=style&index=0&id=7ebe6641&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ebe6641",
  null
  
)

export default component.exports