<template>
  <div id="app" class="site-layout">
    <div class="site-layout__start">
      <site-header />

      <main class="site-content">
        <router-view />
      </main>
    </div>

    <div class="site-layout__end">
      <site-footer />
    </div>

    <notifications group="message" />
  </div>
</template>

<script>
import SiteHeader from "@/components/layout/SiteHeader";
import SiteFooter from "@/components/layout/SiteFooter";
// utils
import { mapActions, mapState } from "vuex";

export default {
  components: {
    SiteHeader,
    SiteFooter,
  },
  computed: {
    ...mapState({
      isModal: (state) => state.settings.modal,
      sWidth: (state) => state.settings.sWidth,
    }),
  },
  watch: {
    isModal(val) {
      this.onChangeModal(val);
    },
  },
  created() {
    this.setHour();
    setInterval(this.setHour, 60 * 1000);
  },
  destroy() {
    clearInterval(this.setHour);
  },
  mounted() {
    this.$nextTick(() => {
      this.getScrollbarWidth();
    });
  },
  methods: {
    ...mapActions({
      setSettings: "settings/setSettings",
      setHour: "time/setHour",
    }),
    getScrollbarWidth() {
      const outer = document.createElement("div");
      outer.style.visibility = "hidden";
      outer.style.overflow = "scroll";
      outer.style.msOverflowStyle = "scrollbar";
      document.body.appendChild(outer);

      const inner = document.createElement("div");
      outer.appendChild(inner);

      const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

      outer.parentNode.removeChild(outer);

      this.setSettings({ key: "sWidth", value: scrollbarWidth });
    },
    onChangeModal(val) {
      const body = document.body;

      if (val) {
        const scrollY = window.scrollY;
        body.classList.add("open-modal");
        body.style.top = "-" + scrollY + "px";
        body.style.paddingRight = this.sWidth + "px";
      } else {
        const scrollY = body.style.top;
        body.classList.remove("open-modal");
        body.style.top = "";
        body.style.paddingRight = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
      }
    },
  },
};
</script>

<style lang="scss">
.site,
.site-shape,
.site-layout {
  height: 100%;
}

.site-shape,
.site-layout {
  display: flex;
  flex-direction: column;
}

.site-layout {
  &__start {
    flex-shrink: 0;
  }

  &__end {
    margin-top: auto;
  }
}

.site-content {
  padding-bottom: 70px;

  @media print {
    padding-bottom: 0;
  }

  @include media-breakpoint-down(md) {
    margin-top: 109px;
  }
}

.open-modal {
  overflow: hidden;
  width: 100vw;
}
</style>
