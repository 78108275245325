import Vue from "vue";
import VueRouter from "vue-router";
import PagesRoutes from "@/modules/pages/router/";
import BookingRoutes from "@/modules/booking/router/";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    ...PagesRoutes.concat(BookingRoutes),
    {
      path: "*",
      redirect: { name: "NotFound" },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    if (to.params.savePosition) return {};

    return { x: 0, y: 0 };
  },
});

export default router;
