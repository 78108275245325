export default [
  {
    path: "/tour",
    name: "ListTour",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/ListTour"),
  },
  {
    path: "/tour/:url",
    name: "ItemTour",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/ItemTour"),
  },
  {
    path: "/tour/:url/book",
    name: "BookTour",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/BookTour"),
  },
  {
    path: "/program",
    name: "ListProgram",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/ListProgram"),
  },
  {
    path: "/program/:url",
    name: "ItemProgram",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/ItemProgram"),
  },
  {
    path: "/program/:url/book",
    name: "BookProgram",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/BookProgram"),
  },
  {
    path: "/charter",
    name: "ListCharter",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/ListCharter"),
  },
  {
    path: "/charter/:url",
    name: "ItemCharter",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/ItemCharter"),
  },
  {
    path: "/charter/:url/book",
    name: "BookCharter",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/BookProgram"),
  },
  {
    path: "/hotel",
    name: "ListHotel",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/ListHotel"),
  },
  {
    path: "/hotel/:url",
    name: "ItemHotel",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/ItemHotel"),
  },
  {
    path: "/hotel/:url/book",
    name: "BookHotel",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/BookHotel"),
  },
  {
    path: "/confirmation",
    name: "Confirmation",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/Confirmation"),
  },
  {
    path: "/success",
    name: "OnlineSuccess",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/OnlineSuccess"),
  },
  {
    path: "/failed",
    name: "OnlineFailed",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/OnlineFailed"),
  },
];
