import Vue from "vue";

import { onTrim } from "@/utils";

const state = {
  buyer: {
    name: null,
    address: null,
    born: null,
    pserie: null,
    pnumber: null,
    pgiven: null,
    pgivenOrg: null,
    pgivenCode: null,
    mobile: null,
    email: null,
  },
  touristCount: 0,
  touristList: [],
  note: null,
};

const actions = {
  onSetBuyer: ({ commit }, payload) => {
    commit("setBuyer", payload);
  },
  onSetNote: ({ commit }, payload) => {
    commit("setNote", payload);
  },
  onSetTouristList: ({ commit }, payload) => {
    commit("setTouristList", payload);
  },
  onSetToruistField: ({ commit }, payload) => {
    commit("setToruistField", payload);
  },
  onClean: ({ commit }) => {
    commit("clean");
  },
};

const mutations = {
  setBuyer(state, payload) {
    Vue.set(state.buyer, payload.field, onTrim(payload.value));
  },
  setNote(state, payload) {
    state.note = payload;
  },
  setTouristList(state, number) {
    const size = state.touristList.length;

    if (size < number) {
      for (let i = size; i < number; i++) {
        state.touristList.push({
          lname: null,
          name: null,
          born: null,
          pserie: null,
          pnumber: null,
          nationality: {
            code: 2,
            name: "Россия",
          },
          sex: null,
          identity: {
            id: 1,
            name: "Паспорт",
          },
        });
      }
    } else if (size > number) {
      const remove = state.touristCount - number;
      state.touristList.splice(-remove);
    }

    state.touristCount = state.touristList.length;
  },
  setToruistField(state, payload) {
    Vue.set(
      state.touristList[payload.index],
      payload.field,
      onTrim(payload.value)
    );
  },
  clean(state) {
    state.buyer = {
      name: null,
      address: null,
      born: null,
      pserie: null,
      pnumber: null,
      pgiven: null,
      pgivenOrg: null,
      pgivenCode: null,
      mobile: null,
      email: null,
    };
    state.touristCount = 0;
    state.touristList = [];
    state.note = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
